import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

const AboutMePage = props => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="I completed my Bachelor degree in KG College of Arts & Science on Computer Technology department. Current studying master (distance education) on Information Technology"
        />
        <title>
          Education - Kalaivanan - Professional front-end/web engineer
        </title>
      </Helmet>
      <div className="about-me page">
        <h1>Education</h1>
        <span className="break-line" />
        <div className="row pt-5">
          <div className="col-12">
            <table className="table">
              <thead>
                <tr>
                  <th>Cource</th>
                  <th>College/University</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>B.Sc Computer Technology</td>
                  <td>KG College of Arts & Science, Coimbatore</td>
                  <td>2013-2016</td>
                </tr>
                <tr>
                  <td>M.Sc Information Technology</td>
                  <td>Bharathiar University, Coimbatore</td>
                  <td>2019-2021</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutMePage
